import { authUserSwrKey } from 'constants/swrKeys'
import dynamic from 'next/dynamic'
import Router, { useRouter } from 'next/router'
import { ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import getAuthUser from 'services/me/getAuthUser'
import useSWR from 'swr'
import { SignUpAsRole } from 'types/App'
import { UserProfile } from 'types/V3/User'
import { useBoolean } from 'usehooks-ts'
import { notifySuccess } from 'utils/toast'
import updateProfile from 'services/me/updateProfile'
import addReferralCode from 'views/authentication/services/addReferralCode'
import useCheckingRole from 'shared/hooks/data-fetching/useCheckingRole'

const AuthenticationModal = dynamic(() => import('views/authentication/components/AuthenticationModal'))

const BlackList = [/\/checkout\/.*/, /^\/u\/[^/]+\/link-in-bio$/, /\/drops\/.*/]

type AuthContextType = {
  showAuthModal: () => void
  closeAuthModal: () => void
  setAuthMode: (mode: 'signin' | 'signup') => void
  setSignUpAsRole: (asRole?: SignUpAsRole) => void
  handleSuccess: (onSuccessCallback: () => void) => void
  setMarketingMessage: (message: string) => void
  setCustomizedSignUpTitle: (title: string) => void
  handleUserSegmentationPopup: (isVisible: boolean) => void
  isUserSegmentationPopupVisible: boolean
  shouldRedirectAfterSegmentation: boolean
}

// This context is used to store app settings that are shared across the app
const AuthContext = createContext<AuthContextType>({
  showAuthModal: () => undefined,
  setAuthMode: () => undefined,
  closeAuthModal: () => undefined,
  setSignUpAsRole: () => undefined,
  handleSuccess: () => undefined,
  setMarketingMessage: () => undefined,
  setCustomizedSignUpTitle: () => undefined,
  handleUserSegmentationPopup: () => undefined,
  isUserSegmentationPopupVisible: false,
  shouldRedirectAfterSegmentation: false,
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const defaultReferralCode = router.query.referralCode as string
  // STATES
  const [onSuccess, setOnSuccess] = useState<(() => void) | undefined>(undefined)
  const [marketingMessage, setMarketingMessage] = useState<string | undefined>(undefined)
  const [customizedSignUpTitle, setCustomizedSignUpTitle] = useState<string | undefined>(undefined)
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signup')
  const hasUpdatedProfileForReferralCode = useRef(false)
  const hasUpdatedProfileForSegmentationPopup = useRef(false)
  const hasOpenedSegmentationPopupRef = useRef(false)

  // signUpAsRole is used to determine which role the user is signing up as (form for-artists, for-collectors, for-advisors pages)
  const [signUpAsRole, setSignUpAsRole] = useState<SignUpAsRole>()

  const { value: isAuthModalVisible, setFalse: _closeAuthModal, setTrue: showAuthModal } = useBoolean(false)
  const [isUserSegmentationPopupVisible, setIsUserSegmentationPopupVisible] = useState(false)
  const prevAuthModalVisibleRef = useRef<boolean | null>(null)
  const isBlackListedForSegmentationPopup = BlackList.some((regex) => regex.test(router.pathname))

  // HOOKS
  const { data: authUser } = useSWR<UserProfile>(authUserSwrKey, getAuthUser)
  const { hasShownReferralPopup, hasOpenedSegmentationPopup } = authUser?.flags || {}
  const { isUserHenry } = useCheckingRole(authUser?.role)

  // Helper
  const submitWithReferralCode = async ({ referralCode }: { referralCode: string }) => {
    try {
      await addReferralCode(referralCode)
      notifySuccess('Referral code added')
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : 'Invalid code'
      console.error(errorMessage)
    }
  }

  //  HANDLERS
  const handleUserSegmentationPopup = useCallback((isVisible: boolean) => {
    setIsUserSegmentationPopupVisible(isVisible)
  }, [])

  const handleSuccess = useCallback((onSuccessCallback: () => void) => {
    setOnSuccess(() => onSuccessCallback)
  }, [])

  const resetStates = useCallback(() => {
    setSignUpAsRole(undefined)
    setMarketingMessage(undefined)
    setCustomizedSignUpTitle(undefined)
  }, [setSignUpAsRole, setMarketingMessage, setCustomizedSignUpTitle])

  const handleCloseAuthModal = useCallback(() => {
    _closeAuthModal()
    prevAuthModalVisibleRef.current = isAuthModalVisible
    setTimeout(() => {
      resetStates() // NOTE:  only reset the states after the modal is closed
    }, 300)
  }, [isAuthModalVisible, _closeAuthModal, resetStates])

  const contextValue = useMemo(() => {
    return {
      showAuthModal,
      closeAuthModal: handleCloseAuthModal,
      setAuthMode,
      setSignUpAsRole,
      handleSuccess,
      setMarketingMessage,
      setCustomizedSignUpTitle,
      handleUserSegmentationPopup,
      isUserSegmentationPopupVisible,
      shouldRedirectAfterSegmentation: !prevAuthModalVisibleRef.current, // If auth in the sign-up page, redirect after the user segmentation popup
    }
  }, [
    showAuthModal,
    handleCloseAuthModal,
    setAuthMode,
    setSignUpAsRole,
    handleSuccess,
    setMarketingMessage,
    setCustomizedSignUpTitle,
    handleUserSegmentationPopup,
    isUserSegmentationPopupVisible,
  ])

  // SIDE EFFECTS
  useEffect(() => {
    // don't call the api if the updateProfile has already been called
    if (hasUpdatedProfileForReferralCode?.current) return
    // auth user is not yet fetched
    if (hasShownReferralPopup === undefined) return
    if (!hasShownReferralPopup) {
      hasUpdatedProfileForReferralCode.current = true
      updateProfile({ hasShownReferralPopup: true }) // tell the backend that the referral code is already sent
    }
  }, [hasShownReferralPopup])

  useEffect(() => {
    // don't call the api if the updateProfile has already been called
    if (hasUpdatedProfileForSegmentationPopup?.current) return
    // auth user is not yet fetched
    if (hasOpenedSegmentationPopup === undefined) return
    if (!hasOpenedSegmentationPopup && !isBlackListedForSegmentationPopup) {
      hasUpdatedProfileForSegmentationPopup.current = true
      updateProfile({ hasOpenedSegmentationPopup: true }) // tell the backend that user is seeing the SegmentationPopup once, and don't show the modal again
    }
  }, [hasOpenedSegmentationPopup, isBlackListedForSegmentationPopup])

  useEffect(() => {
    // auth user is not yet fetched
    if (hasShownReferralPopup === undefined) return
    if (!hasShownReferralPopup && defaultReferralCode) {
      submitWithReferralCode({ referralCode: defaultReferralCode })
    }
  }, [hasShownReferralPopup, defaultReferralCode])

  useEffect(() => {
    // don't call openSegmentationPopup if the user has already opened it
    if (hasOpenedSegmentationPopupRef?.current) return
    // auth user is not yet fetched
    if (hasOpenedSegmentationPopup === undefined) return
    if (authUser?.role === undefined) return
    if (!hasOpenedSegmentationPopup && isUserHenry && !isBlackListedForSegmentationPopup) {
      hasOpenedSegmentationPopupRef.current = true
      handleUserSegmentationPopup(true) // show the segmentation popup once if the user hasn't opened it before
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOpenedSegmentationPopup, isUserHenry, isBlackListedForSegmentationPopup])

  useEffect(() => {
    // clear the authType query param when the modal is closed
    if (!isAuthModalVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { authType = '', ...nextQuery } = { ...Router.query }
      Router.replace({ pathname: Router.pathname, query: nextQuery }, undefined, { shallow: true })
    }
  }, [isAuthModalVisible])

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      <AuthenticationModal
        isAuthModalVisible={isAuthModalVisible}
        closeAuthModal={handleCloseAuthModal}
        authMode={authMode}
        signUpAsRole={signUpAsRole}
        onSuccess={onSuccess}
        customizedSignUpTitle={customizedSignUpTitle}
        marketingMessage={marketingMessage}
      />
    </AuthContext.Provider>
  )
}

export default AuthContext
