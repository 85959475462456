import '@splidejs/react-splide/css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'simplebar-react/dist/simplebar.min.css'
import 'styles/global.scss'

import Head from 'next/head'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { ToastContainer } from 'react-toastify'
import {
  GOOGLE_ANALYTICS_GA4,
  IS_UNDER_CONSTRUCTION,
  IS_DEVELOPMENT,
  IS_PROD,
  GOOGLE_TAG_MANAGER_CONTAINER_ID,
} from 'configs'
import UnderConstruction from 'shared/components/layout/UnderConstruction'
import { AppPropsWithPageOptions } from 'types/App'
import { ConfirmModalProvider } from 'hooks/common/useConfirmModal'
import { FC } from 'react'
import { LogoutModalProvider } from 'hooks/common/useLogoutModal'
import { AskingRoleModalsProvider } from 'shared/hooks/useAskingRoleModals'
import { CustomRouterProvider } from 'contexts/CustomRouterContext'
import { SWRConfig } from 'swr'
import { AppSettingsProvider } from 'contexts/AppSettingsContext'
import { AppLayoutProvider } from 'contexts/AppLayoutContext'
import { Portal } from '@headlessui/react'
import { AuthProvider } from 'contexts/AuthContext'
import InitialSetupController from 'shared/components/app/InitialSetupController'
import { isHumanUserBrowswer } from 'utils/common'

const TopProgressBar = dynamic(() => import('shared/components/loader/TopProgressBar'), { ssr: false })

// NextJS App Component to initialize the pages
const MyApp = ({ Component, pageProps }: AppPropsWithPageOptions) => {
  if (IS_UNDER_CONSTRUCTION) {
    return (
      <div className="relative h-screen w-screen">
        <UnderConstruction />
      </div>
    )
  }

  return (
    <>
      <AuthProvider>
        <CustomRouterProvider>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
          </Head>
          <InitialSetupController />
          <TopProgressBar />
          <AppSettingsProvider>
            <ConfirmModalProvider>
              <AskingRoleModalsProvider>
                <LogoutModalProvider>
                  <AppLayoutProvider>
                    <Component {...pageProps} />
                  </AppLayoutProvider>
                </LogoutModalProvider>
              </AskingRoleModalsProvider>
            </ConfirmModalProvider>
          </AppSettingsProvider>

          <Portal>
            <ToastContainer position="top-center" newestOnTop pauseOnFocusLoss={false} hideProgressBar icon={false} />
          </Portal>

          {!IS_DEVELOPMENT && isHumanUserBrowswer() && (
            <>
              <Script src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_GA4}`} />
              <Script
                id="google-analytics-script"
                dangerouslySetInnerHTML={{
                  __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('consent', 'default', {
                'analytics_storage': 'denied'
              });
              gtag('config', '${GOOGLE_ANALYTICS_GA4}', { send_page_view: true });
            `,
                }}
              />
            </>
          )}

          <Script
            id="google-tag-manager"
            dangerouslySetInnerHTML={{
              __html: `
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CONTAINER_ID}');
          `,
            }}
          />

          {IS_PROD && (
            <Script
              id="hotjar"
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3666435,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
              }}
            />
          )}

          {/* Pinterest script */}
          {IS_PROD && (
            <>
              <Script
                id="pinterest"
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                  __html: `
              !function(e){if(!window.pintrk){window.pintrk = function () {
                window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var  
                  n=window.pintrk;n.queue=[],n.version="3.0";var  
                  t=document.createElement("script");t.async=!0,t.src=e;var  
                  r=document.getElementsByTagName("script")[0];  
                  r.parentNode.insertBefore(t,r)}}("[https://s.pinimg.com/ct/core.js](https://s.pinimg.com/ct/core.js)");  
                pintrk('load', '2612964683664', {em: '<user_email_address>'});  
                pintrk('page');  
              `,
                }}
              />
            </>
          )}

          {/* HubSpot Embed Code */}
          {IS_PROD && <Script async defer id="hs-script-loader" src="//js.hs-scripts.com/43564049.js" />}

          {/* preload splashscreen background */}
          <div className="invisible fixed left-[-9999px] top-[-9999px] h-4 w-4 bg-[url('/images/onboarding/splash-screen.webp')]" />
        </CustomRouterProvider>
      </AuthProvider>
    </>
  )
}

function withSWRGlobal<P>(component: FC<P>) {
  const AppWithSWR: FC<P> = (props) => (
    <SWRConfig
      value={{
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          // Only retry up to 5 times.
          if (retryCount >= 5) return
          // retry after 5 seconds
          setTimeout(() => revalidate({ retryCount }), 5000)
        },
      }}
    >
      {component(props)}
    </SWRConfig>
  )

  return AppWithSWR
}

export default withSWRGlobal(MyApp)
