import { ChevronDownIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MenuItemHrefType, SubMenuItemType } from 'contexts/AppLayoutContext/configs'
import { ReactNode, useCallback } from 'react'
import Link from 'next/link'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'

export type PageItemGroupProps = {
  activeHref: MenuItemHrefType | null
  label: string
  icon: ReactNode
  href?: MenuItemHrefType
  onClick?: () => void
  subMenu?: Array<SubMenuItemType>
  createMenuItemHref: (href: string | ((username?: string) => string) | undefined) => string
}

export const PageItemGroup = (props: PageItemGroupProps) => {
  const router = useRouter()
  const { label, icon, href, activeHref, onClick, subMenu, createMenuItemHref } = props

  if (!subMenu)
    return (
      <PageItem
        label={label}
        icon={icon}
        hrefLink={createMenuItemHref(href)}
        isActive={activeHref === href}
        onClick={onClick}
      />
    )

  const isActive = subMenu.some((menu) => activeHref === menu.href)
  const handleMenuGroupItemClick = () => {
    if (onClick) onClick()
    // If the active href is one of the href of the sub menu
    if (isActive && activeHref) {
      router.push(createMenuItemHref(activeHref))
    }
    // If the active href is not one of the href of the sub menu
    else if (subMenu[0]?.href) router.push(createMenuItemHref(subMenu[0].href))
  }
  return (
    <Disclosure as="div" className="p-0" defaultOpen={isActive}>
      <DisclosureButton
        className={cn(
          'group flex w-full items-center justify-between',
          'rounded-[8px] px-4 py-2 hover:bg-[#E9E9E9]',
          isActive && 'bg-[#E9E9E9]',
        )}
        onClick={handleMenuGroupItemClick}
      >
        <span className="flex items-center gap-2 text-sm/6 font-medium group-data-[hover]:opacity-80">
          {icon}
          <span>{label}</span>
        </span>
        <ChevronDownIcon className="size-5 transition-all group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
      </DisclosureButton>
      <DisclosurePanel className="mt-2 text-sm/5 ">
        {subMenu.map((menu, index) => (
          <PageItem
            key={index}
            label={menu.label}
            hrefLink={createMenuItemHref(menu.href)}
            isActive={activeHref === menu.href}
            onClick={onClick}
          />
        ))}
      </DisclosurePanel>
    </Disclosure>
  )
}

type PageItemProps = {
  label: string
  icon?: ReactNode
  hrefLink: string
  isActive: boolean
  onClick?: () => void
  hidden?: boolean
}

export const PageItem = (props: PageItemProps) => {
  const { label, icon, hrefLink, isActive, onClick, hidden } = props

  const handleMenuItemClick = useCallback(() => {
    if (hrefLink === '/artist/storefront') {
      trackAmplitudeCustomEvent('edit_storefront_clicked')
    }
    onClick && onClick()
  }, [onClick, hrefLink])

  if (hidden) return null
  return (
    <Link
      passHref
      href={hrefLink}
      className={cn(
        'flex items-center gap-2 rounded-[8px] px-4 py-2 hover:bg-[#E9E9E9]', //
        isActive && 'bg-[#E9E9E9]',
      )}
      onClick={handleMenuItemClick}
    >
      {icon ? icon : <div className="size-6" />}
      <div className="text-sm font-medium">{label}</div>
    </Link>
  )
}
