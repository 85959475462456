import { apiClient } from 'utils/apiClient'
import webApiClient from 'utils/webApiClient'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { mutate } from 'swr'

export const signOutUser = async () => {
  await apiClient.post('/v2/auth/sign-out') // doesn't need to wait this promise at the moment
  const response = await webApiClient.post('/auth/sign-out')
  const { data } = response.data as NewBackEndApiResponseData<{ isLoggedIn: boolean }>
  // clear SWR cache data
  await mutate(() => true, undefined, { revalidate: false })
  return data.isLoggedIn
}

export default signOutUser
